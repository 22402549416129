import React from 'react'

import bootstrapService from '../../../../../../services/BootstrapService'

import CourseInfo from '../../../../../shared/CourseInfo/CourseInfo'
import ArrowButton from '../../../../../shared/Buttons/ArrowButton/ArrowButton'

import dateImage from './images/calendar.svg'
import creditsImage from './images/clock.svg'
import deliveryImage from './images/delivery.svg'

import styles from './CourseCard.module.css'

class CourseCard extends React.Component {
    // Component
    render() {
        const { title, description, credits, releaseDate, subjectArea, imageUrl, deliveryMethod, schedules } = this.props.data;
        const showReleaseDate = bootstrapService.getShowReleaseDate() && releaseDate;

        return (
            <div className={`${styles['content']} ${this.props.className}`} ref={this.props.innerRef} onMouseEnter={(e) => this.props.onMouseEnter(this.props.index)} onMouseLeave={(e) => this.props.onMouseLeave(this.props.index)}>
                <p className={styles['category']}>{subjectArea}</p>
                <h3 className={styles['title']}>{title}</h3>
                <p className={styles['description']}>{description}</p>
                <div className={styles['info']}>
                    <CourseInfo image={creditsImage} property={`${bootstrapService.getCreditsTerm(credits)}`} value={credits.toFixed(1)} />
                    {
                        !schedules && showReleaseDate &&
                        <CourseInfo image={dateImage} property='Publish Date' value={`${new Date(releaseDate).toDateString().split(' ').slice(1).join(' ')}`} />
                    }
                    {
                        !schedules && !showReleaseDate &&
                        <CourseInfo image={deliveryImage} property='Delivery Method' value={deliveryMethod} />
                    }
                    {
                        schedules && schedules[0] &&
                        <CourseInfo image={dateImage} property='Next Session' value={`${new Date(schedules[0].startDate).toDateString().split(' ').slice(1).join(' ')}`} />
                    }
                </div>
                <img src={imageUrl} alt='' className={styles['image']} />
                <ArrowButton className={styles['arrow']} />
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <CourseCard 
  innerRef={ref} {...props}
/>);