import React from 'react'

import bootstrapService from '../../../../services/BootstrapService'
import scheduleService from '../../../../services/ScheduleService'

import CourseInfo from '../../../shared/CourseInfo/CourseInfo'
import PrimaryButton from '../../../shared/Buttons/PrimaryButton/PrimaryButton'
import courseImage from './images/individual-course.svg'
import deliveryImage from './images/delivery.svg'
import dateImage from './images/calendar.svg'
import creditsImage from './images/clock.svg'

import styles from './BuyCourseCard.module.css'

class BuyCourseCard extends React.Component {
    // Constructor
    constructor(props) {
        super(props);

        // State
        this.state = {
            schedule: this.props.data.schedules ? this.props.data.schedules[0] : null
        }

        // Event Binding
        this.clickBuy = this.clickBuy.bind(this);
        this.changeSchedule = this.changeSchedule.bind(this);
    }

    // Event Handlers
    clickBuy(e) {
        e.stopPropagation();
        this.props.onBuy(this.state.schedule);
    }

    changeSchedule(e) {
        this.setState({ schedule: this.props.data.schedules[e.target.value] });
    }

    // Component
    render() {
        const courseId = this.props.data.id;
        const title = this.props.data.title; 
        const description = this.props.data.description;
        const credits = this.props.data.credits;
        const deliveryMethod = this.props.data.deliveryMethod;
        const releaseDate = this.props.data.releaseDate;
        const price = this.props.data.item.price;
        const showReleaseDate = bootstrapService.getShowReleaseDate() && releaseDate;
        const schedules = this.props.data.schedules;

        return (
            <div className={styles['content']} onClick={this.props.onClick} ref={this.props.innerRef}>  
                <div className={styles['circle']}>
                    <img src={courseImage} alt='' className={styles['image']} />
                </div>
                <div className={styles['info']}>
                    <div className={styles['info-container']}>
                        <h3 className={styles['title']}>{title}</h3>
                        <p className={styles['description']}>{description}</p>
                        <div className={styles['features']}>
                            <CourseInfo image={creditsImage} property={bootstrapService.getCreditsTerm(credits)} value={credits.toFixed(1)} />
                            <CourseInfo image={deliveryImage} property='Delivery Method' value={deliveryMethod} />
                            {
                                !schedules && showReleaseDate &&
                                <CourseInfo image={dateImage} property='Publish Date' value={new Date(releaseDate).toDateString().split(' ').slice(1).join(' ')} />
                            }
                        </div>
                        {
                            schedules &&
                            <>
                                <span className={styles['schedules-title']}>Dates & Times</span>
                                <ul className={styles['schedules']} onChange={this.changeSchedule}>
                                    {
                                        schedules.slice(0, 6).map((schedule, scheduleIndex) => {
                                        return <li className={styles['schedule']} key={scheduleIndex}>
                                                <input className={styles['radio']} type='radio' name={`radio${courseId}`} value={scheduleIndex} defaultChecked={scheduleIndex === 0} onClick={e => e.stopPropagation()} />
                                                <span className={styles['radio-text']}>{new Date(schedule.startDate).toLocaleDateString("en-US", {weekday: 'long', month: 'short', day: 'numeric'})}, {new Date(schedule.startDate).toLocaleTimeString("en-US", {hour: "2-digit", minute: "2-digit"})} - {new Date(schedule.endDate).toLocaleTimeString("en-US", {hour: "2-digit", minute: "2-digit"})} ({scheduleService.getTimeZone()})</span>
                                            </li>
                                        })
                                    }
                                </ul>  
                            </>    
                        }
                    </div>
                    <div className={styles['buy-container']}>
                        <div className={styles['price']}>
                            <span className={styles['dollars']}>${String(price).split('.')[0]}</span>
                            <span className={styles['cents']}>{String(price).split('.')[1]}</span>
                        </div>
                        <PrimaryButton className={styles['button']} noAnimate={true} onClick={this.clickBuy}>Buy Now</PrimaryButton>
                    </div>
                </div>
            </div>
        )
    }
}

// Need to forward the ref to the compoent so that parent components can access the inner ref 
export default React.forwardRef((props, ref) => <BuyCourseCard 
  innerRef={ref} {...props}
/>);